<template>
	<div class="activeDetail">
		<div class="flex_r f_jc_b account pt30 pb30 br_1_eee">
			<div class="flex_r f_ai_c pl10">
				<div class="posr account_img">
					<img class="brs_4" height="88" width="88" :src="goods.a_logo" alt="">
				</div>
				<div class="flex_c f_jc_c pl15">
					<span class="pb10 fs18">{{goods.a_title}}</span>
					<span >活动日期： {{goods.starttime}} ~ {{goods.endtime}}</span>
					<span>截至报名时间：  {{goods.baotime}}</span>
					<span>报名商品： {{goods.goodsCount}}</span>
					<span class="pt10">活动要求： {{goods.a_require}}</span>
				</div>
			</div>
			<div class="flex_c f_jc_b pr30">
				<span v-if="goods.end">报名已截至</span>
				<span v-else>报名进行中...</span>
<!--				<el-button @click="$refs.dialogActiveEditRef.handleOpen()">修改活动</el-button>-->
			</div>
		</div>
		<el-tabs v-model="activesName" @tab-click="handleClick">
			<el-tab-pane label="活动详情" name="2"></el-tab-pane>
			<el-tab-pane label="报名商品" name="1"></el-tab-pane>
		</el-tabs>
		<div v-if="activesName === '1'">
			<div class="mb10 mt10">
				<el-input class="w_175" v-model="query"></el-input>
				<el-button type="primary" @click="searchGoods">搜索</el-button>
				<el-button>导出</el-button>
			</div>
			<el-tabs v-model="activeName" @tab-click="handlesClick">
				<el-tab-pane label="全部" name="all"></el-tab-pane>
				<el-tab-pane label="待审核" name="wait"></el-tab-pane>
				<el-tab-pane label="审核驳回" name="bh"></el-tab-pane>
				<el-tab-pane label="待寄样" name="djy"></el-tab-pane>
				<el-tab-pane label="已寄样" name="yjy"></el-tab-pane>
				<el-tab-pane label="已入库" name="yrk"></el-tab-pane>
				<el-tab-pane label="待排期" name="dpq"></el-tab-pane>
				<el-tab-pane label="待直播" name="dzb"></el-tab-pane>
				<el-tab-pane label="待完成" name="dwc"></el-tab-pane>
				<el-tab-pane label="已完成" name="ywj"></el-tab-pane>
			</el-tabs>
			<div class="table_title flex_r bg_table mb10">
				<span>商品信息</span>
				<span>优惠方式/优惠金额</span>
				<span>福利</span>
				<span>试样</span>
				<span>操作</span>
				<span>排期</span>
			</div>
			<div class="br_1_eee" v-for="item in goodsLists" v-bind:key="item">
				<div class="item_title flex_r f_ai_c f_jc_b tc bg_table pl20 pr20 fs12">
					<div>
						<el-checkbox></el-checkbox>
						<span class="pl20 pr20">流水号：</span>
						<span>商品来源：商家报名</span>
					</div>
					<div>
						<img class="mr10" width="16" height="16" src="//img.alicdn.com/imgextra//cb/34/TB1nMIPiqL7gK0jSZFBwu0ZZpXa.png" alt="">
						<span>{{ item.nick }}</span>
					</div>
					<span>
					状态：<span style="color: red;">{{ item.statusInfo }}({{ item.note }})</span>
				</span>
				</div>
				<div class="item_title bg_fff flex_r f_ai_c f_jc_b tc pl20 pr20 fs12 br_t_1_eee br_b_1_eee">
					<div>
						<span class="pr20">报名日期： {{ item.createtime }}</span>
						<span>意向佣金： {{ item.yx_rate }}%</span>
					</div>
					<div>
						<span class="pr20">招商： {{ item.username }}</span>
						<span>商家：{{ item.nick }}</span>
					</div>
				</div>
				<ul class="item_ul flex_r f_ai_c f_jc_b fs12">
					<li class="br_r_1_eee flex_r">
						<div class="br_r_1_eee pr10 mr10">
							<img height="80" width="80" :src="item.pict_url" alt="">
							<el-select class="mt20" v-model="item.cid">
								<el-option :label="item.name" :value="item.cid"></el-option>
							</el-select>
						</div>
						<div class="flex_c f_jc_b">
							<el-link class="lh_12" type="primary" :href="item.item_url" target="_blank">{{ item.title }}</el-link>
							<div>
								<span>在售价：</span>
								<span>{{ item.s_price }}</span>
							</div>
							<div>
								<span>直播价：</span>
								<span>{{ item.zb_price }}</span>
							</div>
							<div class="flex_r">
								<span class="w_50">规格：</span>
								<el-input type="textarea" :value="item.skuItem"></el-input>
							</div>
						</div>
					</li>
					<li class="br_r_1_eee flex_c f_jc_b">
						<div>
							<span class="cr_999">原价(划线价)：</span>
							<span>¥{{ item.s_price }}~{{ item.e_price }}</span>
						</div>
						<div>
							<span class="cr_999">在售价：</span>
							<span>¥{{ item.s_price }}~{{ item.e_price }}</span>
						</div>
						<div v-if="item.yh_type == 1">
							<span class="cr_999">-无：</span>
						</div>
						<div v-if="item.yh_type == 2">
							<span class="cr_999">-拍下立减：</span>
							<span>¥{{item.lj}}</span>
						</div>
						<div v-if="item.yh_type == 3">
							<span class="cr_999">-领券立减：</span>
							<span>¥{{item.lq_lj}}</span>
						</div>
						<div v-if="item.yh_type == 4">
							<span class="cr_999">-联系客服领取：</span>
							<span>¥{{item.kf_lj}}</span>
						</div>
						<div v-if="item.yh_type == 5">
							<span class="cr_999">-其他优惠：</span>
						</div>
						<div v-if="item.yh_type == 6">
							<span class="cr_999">-直接改价：</span>
						</div>
						<div>
							<span class="cr_999">=直播到手价：</span>
							<span>¥{{ item.zb_price }}~{{ item.zb_e_price }}</span>
						</div>
						<div>
							<span class="cr_999">历史最低价：</span>
							<span class="el-icon-s-data cr_ff0"></span>
						</div>
						<div class="tr">
<!--							<el-link class="fs12" type="primary" @click="$refs.dialogRef.handleOpen()">修改优化</el-link>-->
						</div>
					</li>
					<li class="br_r_1_eee flex_c f_jc_b">
						<el-input :rows="3" type="textarea" placeholder="赠品：" :value="item.zp"></el-input>
						<el-input :rows="3" type="textarea" placeholder="抽奖：" :value="item.zbj_cj"></el-input>
					</li>
					<li class="br_r_1_eee">
						<el-input :rows="6" type="textarea" placeholder=""></el-input>
					</li>
					<li class="br_r_1_eee flex_c f_jc_a f_ai_c">
						<span class="cur_p" @click="$refs.dialogDataRef.handleOpen()">查看数据</span>
						<router-link tag="a" target="_blank" :to="{path:'/goodsDetail', query:{id:item.id}}">报名详情</router-link>
						<el-popover
								placement="top-start"
								title=""
								width="250"
								trigger="hover">
							<ul>
								<li class="flex_r f_jc_b mt2 mb2" v-if="item.c_id">
									<span v-if="item.c_status == 1">合同： 待签约</span>
									<span v-else-if="item.c_status == 2">合同： 已签约</span>
									<span v-else-if="item.c_status == 3">合同： 作废</span>
								</li>
								<li class="flex_r f_jc_b mt2 mb2" v-else>
									<span>合同： 待发起</span>
<!--									<el-button @click="sponsorHandle(1)" type="danger">发起</el-button>-->
								</li>
								<li class="flex_r f_jc_b mt2 mb2" v-if="item.e_id">
									<span v-if="item.e_status == 1">保证金： 待付款</span>
									<span v-else-if="item.e_status == 2">保证金： 已付款</span>
									<span v-else-if="item.e_status == 3">保证金： 已到账</span>
									<span v-else-if="item.e_status == 4">保证金： 已退还</span>
								</li>
								<li class="flex_r f_jc_b mt2 mb2" v-else>
									<span>保证金： 待发起</span>
<!--									<el-button @click="sponsorHandle(2)" type="danger">发起</el-button>-->
								</li>
								<li class="flex_r f_jc_b mt2 mb2" v-if="item.f_id">
									<span v-if="item.e_status == 1">服务费： 待付款</span>
									<span v-else-if="item.e_status == 2">服务费： 已付款</span>
									<span v-else-if="item.e_status == 3">服务费： 已到账</span>
									<span v-else-if="item.e_status == 4">服务费： 已退还</span>
								</li>
								<li class="flex_r f_jc_b mt2 mb2" v-else>
									<span>服务费： 待发起</span>
<!--									<el-button @click="sponsorHandle(3)" type="danger">发起</el-button>-->
								</li>
								<!--							<li class="flex_r f_jc_b mt2 mb2">-->
								<!--								<span>链接： 待提交</span>-->
								<!--								<el-button @click="sponsorHandle(4)" type="danger">发起</el-button>-->
								<!--							</li>-->
								<li class="flex_r f_jc_b mt2 mb2" v-if="item.status == 0">
									<span>样品： 待寄送</span>
<!--									<el-button @click="sponsorHandle(5)" type="danger">发起</el-button>-->
								</li>
								<li class="flex_r f_jc_b mt2 mb2" v-else>
								</li>
							</ul>
							<span slot="reference" class="cr_ff0 cur_p">交易服务</span>
						</el-popover>
						<router-link tag="a" target="_blank" :to="{path:'/goodsDetail', query:{id:item.id}}">备注</router-link>
					</li>
					<li class="br_r_1_eee">
						<el-select class="" v-model="item.statusInfo">
							<el-option :label="item.statusInfo" :value="item.statusInfo"></el-option>
						</el-select>
						<el-date-picker
								style="width:150px"
								class="mt10"
								v-model="value1"
								type="date"
								placeholder="选择排期">
						</el-date-picker>
						<div class="mt10 mb10" v-if="item.zbtime">
							<span class="cr_999">排期：</span>
							<span>{{ item.zbtime }}</span>
						</div>
						<div class="mt10 mb10" v-else>
							<span class="cr_999">排期：</span>
							<span>-</span>
						</div>
						<div v-if="item.act_id">
							<span class="cr_999">活动：</span>
							<span>{{ item.a_title }}</span>
						</div>
						<div v-else>
							<span class="cr_999">活动：</span>
							<span>-</span>
						</div>
					</li>
				</ul>
			</div>
			<el-pagination
					class="tr"
					background
					:small="false"
					@current-change="handleCurrentChange"
					:current-page.sync="currentPage"
					:page-size="pageSize"
					:total="total">
			</el-pagination>
		</div>
		<div v-if="activesName === '2'" v-html="goods.a_desc"></div>
		<e-dialog title="选择优惠" ref="dialogRef">
			<span>优惠方式：</span>
			<el-select>
				<el-option label="1" value="1"></el-option>
			</el-select>
		</e-dialog>
		<e-dialog width="1000px" title="查看数据" ref="dialogDataRef">
			<Empty />
		</e-dialog>
		<e-dialog :title="dialogTitle" ref="dialogSponsorRef">
			<e-form :formConfList="formConfListItem" />
		</e-dialog>
		<e-dialog width="800" title="修改活动" ref="dialogActiveEditRef">
			<e-form :formConfList="activityEditFormConf">
				<template #1>
					<el-upload
						class="avatar-uploader"
						action="https://jsonplaceholder.typicode.com/posts/"
						:show-file-list="false"
						:on-success="handleAvatarSuccess"
						:before-upload="beforeAvatarUpload">
						<img src="https://filenew.lxshuju.com/618121/20201114/38923356aab29e43bc65133b2403d12b.jpg" class="avatar">
						<!-- <i class="el-icon-plus avatar-uploader-icon"></i> -->
						</el-upload>
				</template>
				<template #7>
					<editor />
				</template>
			</e-form>
		</e-dialog>
	</div>
</template>

<script>
import {businessTableConf, searchFormList, commodityDetailsTableConf} from '@data/business'
import {formConfList} from '@data/handCard'
import { optionPie} from '@data/dashboard'
import {activityEditFormConf} from '@data/activity'
export default {
	name: 'ActiveDetail',
	data() {
		return {
			activityEditFormConf,
			activeName: "all",
			form: {},
			commodityDetailsTableConf,
			businessTableConf,
			optionPie,
			searchFormList,
			formConfList,
			formConfListItem: [],
			dataOrigin: {
				data: []
			},
			activesName: "2",
			currentPage: 1,
			pageSize: 10,
			total: 0,
			dialogTitle: '',
			goods: {},
			goodsLists: [],
			cateLists: [],
			query: '',
			id: 0
		}
	},
	methods: {
		searchGoods() {
			this.currentPage = 1
			this.GetDetail()
		},
		handleCurrentChange(val){
			this.currentPage = val
			this.GetDetail()
		},
		handlesClick() {
			this.currentPage = 1
			this.form = {}
			this.GetDetail()
		},
		handleClick() {},
		dialogOpen() {
			this.$refs.dialogSponsorRef.handleOpen();
		},
		GetDetail(){
			let that = this
			that.dataOrigin.data = []
			this.get('?server=Live.Goods.Active.Detail.Info.Get',{id:this.id,stype:this.activeName,query:this.query}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				console.log(response)
				that.goods = response.data.goods
				that.goodsLists = response.data.lists.data
				that.total = response.data.lists.total
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
	},
	created() {
		this.id = this.$route.query.id

		if(!this.id){
			this.$message.error('请求非法')
		} else {
			this.GetDetail()
		}
	}
}
</script>

<style lang="scss" scoped>
.account {
	height: 88px;
	.account_img {
		span {
			height: 20px;
			left: 0;
			right: 0;
			bottom: 1px;
			line-height: 20px;
			background-color: rgba($color: #000000, $alpha: .1);
		}
	}
	li {
		height: 88px;
		padding-left: 50px;
	}
}
.echart {
	height: 200px;
}
.w140 {
	width: 140px;	
}
.table_title {
	span {
		line-height: 40px;
		width: 15%;
		text-align: center;
		&:nth-child(1) {
			width: 25%;
		}
	}
}
.item_title {
	line-height: 30px;
}
.item_ul {
	li {
		width: 15%;
		padding: 10px;
		height: 140px;
		&:nth-child(1) {
			width: 25%;
		}
	}
}
.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
.avatar-uploader .el-upload:hover {
	border-color: #409EFF;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}
.avatar {
	width: 178px;
	height: 178px;
	display: block;
}
</style>